<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside admin-media">
				<OrderNav></OrderNav>
			</div>
			<div class="admin-col whole_000 flex_bd">
				<div class="order_box">
					<div class="order-top whole_000 flex_bd">
						<div class="or-top-t">{{$t('wTxt')}}</div>
						<div class="or-top-b">{{$t('hisTxt')}}</div>
					</div>
					<div class="order-top order-body whole_fff flex_bd">
						<div class="or-bd-top flex flex-cStart flex-zBetween">
							<div class="order-sel">
								<div class="acc-sel-flex acc_sel_time">
									<div class="acc-sel-t">{{$t('wtTxt')}}：</div>
									<el-date-picker v-model="daterange" type="daterange" range-separator="-"
										format="yyyy-MM-dd" value-format="timestamp" :start-placeholder="$t('labStart')"
										:end-placeholder="$t('labEnd')">
									</el-date-picker>
								</div>
								<div class="acc-sel-flex">
									<div class="acc-sel-t">{{$t('wTxt3')}}</div>
									<el-select v-model="symbol" :placeholder="$t('placeholder3')">
										<el-option v-for="(item,index) in options" :key="index" :label="item.symbol"
											:value="item.symbol">
										</el-option>
									</el-select>
								</div>
								<div class="acc-sel-flex">
									<div class="acc-sel-t">{{$t('wtth2')}}</div>
									<el-select v-model="type" :placeholder="$t('placeholder3')">
										<el-option v-for="(item,index) in options1" :key="index" :label="$t(item.label)"
											:value="item.value">
										</el-option>
									</el-select>
								</div>
								<div class="acc-sel-flex">
									<div class="acc-sel-t">{{$t('wTxt4')}}</div>
									<el-select v-model="direction" :placeholder="$t('placeholder3')">
										<el-option v-for="(item,index) in options2" :key="index" :label="$t(item.label)"
											:value="item.value">
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="order_bt flex flex-ac">
								<div class="acc-in-r acc-btn">
									<el-button type="primary" class="btn" @click="search">{{$t('btn6')}}</el-button>
								</div>
								<el-button type="primary" class="btn btn_hui" @click="clear">{{$t('adbtn')}}</el-button>
							</div>
						</div>
						<div class="order-table">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column label="#" type="index">
								</el-table-column>
								<el-table-column prop="time" :label="$t('wth1')">
									<template slot-scope="scope">
										<span>{{ scope.row.time | datefmt('YYYY-MM-DD HH:mm:ss') }}</span>
									</template>
								</el-table-column>
								<el-table-column prop="symbol" :label="$t('wTxt3')">
								</el-table-column>
								<el-table-column prop="type" :label="$t('wtth2')">
									<template slot-scope="scope">
										<span v-if="scope.row.type == 'LIMIT_PRICE'">{{ $t('dhTxt2') }}</span>
										<span v-if="scope.row.type == 'MARKET_PRICE'">{{ $t('trTxt2') }}</span>
									</template>
								</el-table-column>
								<el-table-column prop="direction" :label="$t('wth2')">
									<template slot-scope="scope">
										<span v-if="scope.row.direction == 'BUY'">{{$t('dhTxt4')}}</span>
										<span v-if="scope.row.direction == 'SELL'">{{$t('dhTxt3')}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="price" :label="$t('wth3')">
								</el-table-column>
								<el-table-column prop="amount" :label="$t('wtth4')">
								</el-table-column>
								<el-table-column prop="turnover" :label="$t('wth4')">
								</el-table-column>
								<el-table-column prop="tradedAmount" :label="$t('wth5')">
								</el-table-column>
								<el-table-column prop="status" :label="$t('wtth8')">
									<template slot-scope="scope">
										<!-- <span v-if="scope.row.status == 'TRADING'">{{$t('dhTxt4')}}</span> -->
										<span v-if="scope.row.status == 'COMPLETED'">{{$t('orTab3')}}</span>
										<span v-if="scope.row.status == 'CANCELED'">{{$t('orTab4')}}</span>
										<!-- <span v-if="scope.row.status == 'OVERTIMED'">{{$t('dhTxt3')}}</span> -->
									</template>
								</el-table-column>
								<template slot="empty" class="exte_empty">
									<img src="../../assets/photo/exte1.png" alt="">
									<p>{{$t('czEmpty')}}</p>
								</template>
							</el-table>
						</div>
						<div class="exte-box">
							<el-pagination layout="prev, pager, next" @current-change="handleCurrentChange"
								:current-page.sync="currentPage" :total="total" :background="true"></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		perHistory
	} from '@/api/api/exchange'
	import {
		symbolThumb,
	} from '@/api/api/market'
	import OrderNav from '@/components/OrderNav.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			OrderNav,
			Foot
		},
		data() {
			return {
				options: [],
				options1: [{
					label: 'dhTxt2',
					value: 'LIMIT_PRICE'
				}, {
					label: 'trTxt2',
					value: 'MARKET_PRICE'
				}],
				options2: [{
					label: 'dhTxt4',
					value: 0
				}, {
					label: 'dhTxt3',
					value: 1
				}],
				daterange: '',
				symbol: '',
				type: '',
				direction: '',
				tableData: [],
				currentPage: 1,
				pageSize: 10,
				total: 10
			};
		},
		mounted() {
			this.getList()
			this.getSymbol()
		},
		methods: {
			getList() {
				perHistory({
					direction: this.direction,
					endTime: this.daterange[1],
					startTime: this.daterange[0],
					symbol: this.symbol,
					type: this.type,
					pageNo: this.currentPage,
					pageSize: this.pageSize
				}).then(res => {
					console.log(res)
					this.tableData = res.content
					this.total = res.totalElements
					this.pageSize = res.size
				})
			},
			getSymbol() {
				symbolThumb().then(res => {
					this.options = res
				})
			},
			search() {
				this.getList()
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getList()
			},
			clear() {
				this.daterange = '';
				this.symbol = '';
				this.type = '';
				this.direction = '';
				this.getList()
			}
		}
	}
</script>
